import React from 'react';

import Container from 'components/Container/Container';
import Layout from 'components/Layout';
import Seo from 'components/Seo';

const NotFoundPage = () => (
  <Layout>
    <Seo title="404: Not found" />
    <Container>
      <h1>Πρόβλημα!</h1>
      <p>Ευτυχώς όχι μεγάλο αλλά δυστυχώς η σελίδα που ψάχνεις δεν βρέθηκε…</p>
    </Container>
  </Layout>
);

export default NotFoundPage;
